export const projects = [
  {
    id: 1,
    title: "Portfolio React",
    description: "Un portfolio personnel construit avec React pour présenter mes projets et compétences.",
    link: "https://nicolasgayet.fr",
    image: "./logo192.png"
  },
  {
    id: 4,
    title: "Sokoban",
    description: "Un jeu de réflexion où le joueur doit pousser des caisses pour les placer sur des emplacements cibles.",
    link: "https://nicolasgayet.fr/sokoban",
    image: "./logo192.png"
  }
];