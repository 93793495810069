import React from 'react';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer>
      <p>© 2024 Nicolas Gayet - Tous droits réservés</p>
     {/* <div>
        <a href="https://www.linkedin.com/in/nicolas-gayet-87b767176/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        <a href="https://gitlab.com/Gayet-Nicolas" target="_blank" rel="noopener noreferrer">GitHub</a>
      </div>*/}
    </footer>
  );
}

export default Footer;