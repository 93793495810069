import React from 'react';
import '../styles/About.css';

function About() {
  return (
    <div className="about">
      <h2>À propos de moi</h2>
      <img src={`${process.env.PUBLIC_URL}/images/photo-nicolas.jpg`} alt="Nicolas Gayet" className="profile-photo" />
      <p>
        Je suis Nicolas Gayet, un développeur web passionné avec un parcours
        riche en apprentissages, me permettant de maîtriser à la fois les
        aspects réseaux et le développement web.
      </p>
      <h3>Mon Parcours</h3>
      <ul>
        <li>
          <strong>Bac Pro SEN (Systèmes Électroniques Numériques) - Option Réseaux : </strong> 
          Découverte des bases des systèmes informatiques, réseaux et télécommunications.
        </li>
        <li>
          <strong>BTS SNIR (Systèmes Numériques, option Informatique et Réseaux) : </strong> 
          Approfondissement en développement informatique et gestion des réseaux.
        </li>
        <li>
          <strong>Licence Pro DEV WEB - Métiers de l’informatique : applications Web : </strong> 
          Expertise en création d’applications web, e-commerce, et gestion des données
          dans un contexte Big Data.
        </li>
      </ul>
      <h3>Mes Compétences</h3>
      <ul>
        <li>
          <strong>Développement Frontend : </strong> JavaScript, React, HTML, CSS
        </li>
        <li>
          <strong>Développement Backend : </strong> Node.js, Express, PHP, Laravel
        </li>
        <li>
          <strong>Gestion de Bases de Données : </strong> MySQL, PostgreSQL
        </li>
        <li>
          <strong>Administration Systèmes et Réseaux : </strong> Linux, gestion de serveurs web 
          (Apache, Nginx), sécurité réseau (firewall, SSH, certificats SSL)
        </li>
        <li>
          <strong>Déploiement et Automatisation : </strong> Docker, GitLab CI/CD
        </li>
        <li>
          <strong>Big Data et Analyse : </strong> Bases en gestion de données massives et optimisation 
          des performances.
        </li>
      </ul>
    </div>
  );
}

export default About;
